<template>
    <div class="mianbox">
        <div class="screenbox">
            <div class="screen">
                <div class="screenbox-one">
                    <el-form ref="form" :model="form" label-width="80px" class="searchbox_left">
                        <div class="leftbox">
                            <div class="left_one">
                                <el-input
                                    placeholder="请输入项目名称或项目编号查询"
                                    v-model="form.name"
                                    size="small">
                                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                                </el-input>
                            </div>
                            <div class="left_three">
                                <el-button type="primary" size="small" plain  @click="onSubmit">查询</el-button>
                            </div> 
                        </div>
                    </el-form>
                    <div>
                        <el-radio v-model="radio" label="1"><i class="el-icon-s-grid"></i>列表</el-radio>
                        <el-radio v-model="radio" label="2"><i class="el-icon-map-location"></i>地图</el-radio>
                    </div>
                    <div @click="zksq">
                        <i :class="this.show?'el-icon-caret-top':'el-icon-caret-bottom'" style="font-size:2.4rem;color:#999999"></i>
                    </div>
                </div>
                <el-collapse-transition>
                    <div v-show="show" class="conditionbox">
                        <!--来源筛选-->
                        <div class="conditionbox_one">
                            <label class="biaoqian">
                                数据来源：
                            </label>
                            <ul class="sourcebox" style="margin:0;padding:0">
                                <li  v-for="(item, index) in menuList" :key="index" :class="{ active_li: item.id === menuselect }" @click="menuchange(item)">
                                    {{item.name}}
                                </li>
                            </ul>
                        </div>
                        <!--用途筛选-->
                        <div class="conditionbox_two">
                            <label class="biaoqian">
                                用途类型：
                            </label>
                            <ul class="sourcebox" style="margin:0;padding:0">
                                <li  v-for="(item, index) in useType" :key="index" :class="{ active_li: item.id === useselect }" @click="usechange(item)">
                                    {{item.name}}
                                </li>
                            </ul>
                        </div>
                        <!--时间筛选-->
                        <div class="conditionbox_three">
                            <label class="biaoqian">
                                时间：
                            </label>
                            <div class="timebox">
                                <el-date-picker
                                    v-model="timevalue"
                                    type="daterange"
                                    size="small"
                                    @change='timechange()'
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                                </el-date-picker>
                            </div>
                        </div>
                        <!--范围筛选-->
                        <div class="conditionbox_four">
                            <label class="biaoqian">
                                范围筛选：
                            </label>
                            <div class="locationbox">
                                <el-input
                                    placeholder="请输入地点，或直接在地图上选择地点"
                                    v-model="form.location"
                                    size="small">
                                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                                </el-input>
                            </div>
                            <div class="Radiobox">
                                 <el-radio-group v-model="Range">
                                    <el-radio :label="item.label" v-for="(item, index) in Rangelist" :key="index" >{{item.name}}</el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                    </div>
                </el-collapse-transition>
            </div>
            <div class="listbox" v-if="radio==='1'">
                <el-table
                :data="tableData"
                border
                :style="{width: '100%','margin-bottom':'2rem'}"
                :header-cell-style="{background:'#f5f7fa'}">
                    <el-table-column v-for="(item, index) in tableList" :key="index" :prop="item.prop" :label="item.label"></el-table-column>
                    <el-table-column
                        fixed="right"
                        label="操作"
                        width="120">
                        <template slot-scope="scope">
                            <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                            <el-button type="text" size="small" @click="handleDelete(scope.$index, scope.row)" slot="reference">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page="paging.currentPage"
                    :page-sizes="[10]"
                    :page-size="paging.size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="paging.total">
                </el-pagination>
            </div>
        </div>
        <div class="Mapbox" v-if="radio==='2'">

        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                //范围选择
                Range:'1',
                Rangelist:[
                    {
                        label:'1',
                        name:'500m'
                    },{
                        label:'2',
                        name:'1000m'
                    },{
                        label:'3',
                        name:'3000m'
                    }
                ],
                //列表展示的值
                tableList:[
                    {
                        prop:'number',
                        label:'项目编号'
                    },
                    {
                        prop:'name',
                        label:'项目名称'
                    },
                    {
                        prop:'source',
                        label:'数据来源'
                    },
                    {
                        prop:'purpose',
                        label:'用途类型'
                    },
                    {
                        prop:'price',
                        label:'价格（元）'
                    },
                    {
                        prop:'time',
                        label:'时间'
                    }
                ],
                //分页
                paging:{
                    currentPage:4,//当前加载页
                    size:10,//每页显示条目个数
                    total:400,//总条目数
                },
                //表单数据
                tableData: [
                    {
                        number: '1',//项目编号
                        name: '南湖半岛',//项目名称
                        source: '房产交易数据',//数据来源
                        purpose: '房产-住宅',//用途类型
                        price: '2100000',//价格
                        time: '2022-01-02',//时间
                    },
                    {
                        number: '2',//项目编号
                        name: '南湖半岛',//项目名称
                        source: '房产交易数据',//数据来源
                        purpose: '房产-住宅',//用途类型
                        price: '2100000',//价格
                        time: '2022-01-02',//时间
                    },
                    {
                        number: '3',//项目编号
                        name: '南湖半岛',//项目名称
                        source: '房产交易数据',//数据来源
                        purpose: '房产-住宅',//用途类型
                        price: '2100000',//价格
                        time: '2022-01-02',//时间
                    },
                    {
                        number: '4',//项目编号
                        name: '南湖半岛',//项目名称
                        source: '房产交易数据',//数据来源
                        purpose: '房产-住宅',//用途类型
                        price: '2100000',//价格
                        time: '2022-01-02',//时间
                    },
                    {
                        number: '5',//项目编号
                        name: '南湖半岛',//项目名称
                        source: '房产交易数据',//数据来源
                        purpose: '房产-住宅',//用途类型
                        price: '2100000',//价格
                        time: '2022-01-02',//时间
                    },
                    {
                        number: '6',//项目编号
                        name: '南湖半岛',//项目名称
                        source: '房产交易数据',//数据来源
                        purpose: '房产-住宅',//用途类型
                        price: '2100000',//价格
                        time: '2022-01-02',//时间
                    },
                    {
                        number: '7',//项目编号
                        name: '南湖半岛',//项目名称
                        source: '房产交易数据',//数据来源
                        purpose: '房产-住宅',//用途类型
                        price: '2100000',//价格
                        time: '2022-01-02',//时间
                    },
                    {
                        number: '8',//项目编号
                        name: '南湖半岛',//项目名称
                        source: '房产交易数据',//数据来源
                        purpose: '房产-住宅',//用途类型
                        price: '2100000',//价格
                        time: '2022-01-02',//时间
                    },
                    {
                        number: '9',//项目编号
                        name: '南湖半岛',//项目名称
                        source: '房产交易数据',//数据来源
                        purpose: '房产-住宅',//用途类型
                        price: '2100000',//价格
                        time: '2022-01-02',//时间
                    },
                    {
                        number: '10',//项目编号
                        name: '南湖半岛',//项目名称
                        source: '房产交易数据',//数据来源
                        purpose: '房产-住宅',//用途类型
                        price: '2100000',//价格
                        time: '2022-01-02',//时间
                    },
                ],
                //时间范围
                timevalue: '',
                //数据来源
                menuList:[
                    {
                        id:1,
                        name:'房产交易数据'
                    },
                    {
                        id:2,
                        name:'司法数据'
                    },
                    {
                        id:3,
                        name:'土地信息'
                    },
                    {
                        id:4,
                        name:'基准地价数据'
                    },
                    {
                        id:5,
                        name:'历史项目'
                    }
                    
                ],
                menuselect:1,
                //用途类型：
                useType:[
                    {
                        id:null,
                        name:'不限'
                    },
                    {
                        id:1,
                        name:'房产-住宅'
                    },
                    {
                        id:2,
                        name:'房产-商业'
                    },
                    {
                        id:3,
                        name:'房产-工业'
                    },
                    {
                        id:4,
                        name:'房产-车位'
                    },
                    {
                        id:5,
                        name:'房产-办公'
                    },
                    {
                        id:6,
                        name:'房产-酒店'
                    },
                    {
                        id:7,
                        name:'在建工程'
                    },
                    {
                        id:8,
                        name:'土地-住宅用地'
                    },
                    {
                        id:9,
                        name:'土地-商业用地'
                    },
                    {
                        id:10,
                        name:'土地-工业用地'
                    },
                    {
                        id:11,
                        name:'矿权'
                    },
                    {
                        id:12,
                        name:'林权'
                    },
                    {
                        id:13,
                        name:'股权'
                    },
                    {
                        id:14,
                        name:'机械设备'
                    },
                    {
                        id:15,
                        name:'其他'
                    },
                    
                ],
                useselect:null,
                //搜索关键词
                form: {
                    name: '',
                    location:'',
                },
                //展示形式
                radio: '1',
                //展开收起
                show:true,
            }
        },
        methods: {
            //查看
            handleClick(row) {
                console.log(row);
            },
            //删除
            handleDelete(index, row){
                this.$confirm('您确定删除编号为：'+row.number+'的文件吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                .then(() => {
                    this.tableData.splice(index, 1);
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                })
                .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除'
                        });          
                });
                console.log(index, row);
            },
            //分页
            //当前加载页 改变时会触发
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
            },
            //时间改变
            timechange(){
                console.log(this.timevalue)
            },
            //数据来源点击改变
            menuchange(item){
                this.menuselect=item.id
            },
            //用途类型点击改变
            usechange(item){
                this.useselect=item.id
            },
            //查询按钮
            onSubmit() {
                console.log(this.form);
            },
            zksq(){
                if(this.show==false){
                    this.show=true
                }else{
                    this.show=false
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .mianbox{
        width: 100%;
        height: 100%;
        position: relative;
        .screenbox{
            box-sizing: border-box;
            position: absolute;
            top: 1.5rem;
            left: 1.5rem;
            right: 1.5rem;
            .screen{
                background-color: #ffffff;
                box-shadow: 0 0 0.6rem #e0e0e0;
                border-radius:0.5rem ;
                .screenbox-one{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 6rem;
                    padding: 0 1.5rem;
                    .searchbox_left{
                        .leftbox{
                            display: flex;
                            align-items: center;
                            .left_one{
                                width: 26.8rem;
                            }
                            .left_three{
                                margin-left:2rem ;
                            }
                        }
                    }
                }
                .conditionbox{
                    .conditionbox_one{
                        border-top:1px dotted #E5E5E5;
                        padding: 1.5rem;
                        display: flex;
                        align-items: center;
                        .biaoqian{
                            width: 60px;
                            color: #999999;
                            font-size: 1.2rem;
                            margin-right: 1rem;
                        }
                        .sourcebox{
                            width: 92%;
                            display: flex;
                            align-items: center;
                            list-style:none;
                            li{
                                list-style:none;
                                font-size: 1.2rem;
                                margin-right: 2.4rem;
                                color: #333333;
                                cursor: pointer;
                            }
                            .active_li{
                                padding: 0.4rem 0.8rem;
                                background-color: #fdf6ec;
                                border: 1px solid #faecd8;
                                border-radius: 0.4px;
                                color: #e6a23c;
                            }
                        }
                    }
                    .conditionbox_two{
                        border-top:1px dotted #E5E5E5;
                        padding: 1.5rem;
                        display: flex;
                        align-items: center;
                        .biaoqian{
                            width: 60px;
                            color: #999999;
                            font-size: 1.2rem;
                            margin-right: 1rem;
                        }
                        .sourcebox{
                            width: 92%;
                            display: flex;
                            align-items: center;
                            list-style:none;
                            flex-wrap: wrap;
                            li{
                                list-style:none;
                                font-size: 1.2rem;
                                margin-right: 2.4rem;
                                color: #333333;
                                cursor: pointer;
                            }
                            .active_li{
                                padding: 0.4rem 0.8rem;
                                background-color: #fdf6ec;
                                border: 1px solid #faecd8;
                                border-radius: 0.4px;
                                color: #e6a23c;
                            }
                        }
                    }
                    .conditionbox_three{
                        border-top:1px dotted #E5E5E5;
                        padding: 1.5rem;
                        display: flex;
                        align-items: center;
                        .biaoqian{
                            width: 60px;
                            color: #999999;
                            font-size: 1.2rem;
                            margin-right: 1rem;
                        }
                        .timebox{
                            width: 92%;
                        }
                    }
                    .conditionbox_four{
                        border-top:1px dotted #E5E5E5;
                        padding: 1.5rem;
                        display: flex;
                        align-items: center;
                        .biaoqian{
                            width: 60px;
                            color: #999999;
                            font-size: 1.2rem;
                            margin-right: 1rem;
                        }
                        .locationbox{
                           width: 30rem; 
                        }
                        .Radiobox{
                            margin-left: 2rem;
                        }
                    }
                }
            } 
            .listbox{
                margin-top: 1.5rem;
                background-color: #ffffff;
                box-shadow: 0 0 0.6rem #e0e0e0;
                border-radius:0.5rem;
                padding: 2rem;
                margin-bottom:2rem;
            }   
        }
        .Mapbox{
            width: 100%;
            height: 100%;
            background-color: #e6a23c;
        }
    }
</style>